import React, {useState} from 'react';
import { Link } from 'gatsby'
import OptimizeExample from './OptimizeExample';
import PopularExample from './PopularExample';
import Drawer from '../../components/UiComponent/drawer'
import WrapperStyle from './examples.style';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import {StaticImage} from 'gatsby-plugin-image';
import ScriptTag from 'react-script-tag'
import PlatformExample from './PlarformExample';

export default () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>LINEのキャンペーン事例・サンプル
                    </h1>
                    <p>
                        お客様の公式アカウントでキャンペーンを開催できます。リタッチで構築したキャンペーンでは参加者のLINEアカウントと自動連係されるため、参加者はキャンペーンへの応募が簡単になります。
                    </p>
                </div>
                <div className="row">
                    <div className="container" style={{textAlign: 'center'}}>
                        <a data-retach-widget="embed" href="https://retach.biz/promotions/3fc5b992bce6791c3e" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                        {/*<h5 className="pt-70">LINEのサンプルキャンペーンの動作はリタッチ(@retach)のLINE公式アカウントからご確認頂けます。</h5>*/}
                        {/*<p>*/}
                        {/*    <a href="https://lin.ee/0MS9388"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"/></a>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </div>
        </section>
          <PlatformExample title="LINEのキャンペーンサンプル" types={['lineReceipt', 'lineReferral','twitterToLine','lineInstantWin','lineCoupon']} />
          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                              <AccordionItem>
                                  <AccordionItemHeading>
                                      <AccordionItemButton>
                                          当選者にLINEから当選メッセージを送ることはできますか？
                                      </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                      <p>
                                          はい、可能です。リタッチのダッシュボードの当選者一覧からLINEのUserIdをCSVファイル形式でダウンロードできます。
                                          そのCSVファイルを「LINE公式アカウントの管理画面」から、アップロードしてオーディエンスを作成していただくことで一括送信できます。
                                      </p>
                                  </AccordionItemPanel>
                              </AccordionItem>
                              <AccordionItem>
                                  <AccordionItemHeading>
                                      <AccordionItemButton>
                                          LINEのシェア応募とは何ですか？
                                      </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                      <p>
                                          LINEのシェア応募はLINEの友だちにしかシェアできませんが、リッチメニューでシェアされるため、既読率は平均80%以上、参加率は平均50%以上と非常に高いです。
                                          <StaticImage src={"../../assets/images/examples/line/line-share-flow.jpg"} />
                                      </p>
                                  </AccordionItemPanel>
                              </AccordionItem>

                            <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      ホームページとLINEアプリの両方でキャンペーンを開催したいのですが、可能ですか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。LINEアプリとWEBページから応募することが可能です。
                                  </p>
                              </AccordionItemPanel>
                            </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      LINE公式アカウント以外に必要なものはありますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      LINE Developerアカウントが必要になります。詳しい設定方法については「使い方」の<Link to={'/documents/line-app'}>「LINEアプリでキャンペーン」</Link>をご覧ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      LINE公式アカウントを複数もっていますが、１つのキャンペーンで複数のLINE公式アカウントと連携することは可能ですか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      １つのキャンペーンで連携できる公式アカウントは1つのみです。１つのプランで、複数の公式アカウントを使用することができますが顧客管理が複雑になるので、1つプランで１つのLINE公式アカウントをお勧めしております。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <PopularExample exclude={['lineReferral','twitterToLine','lineInstantWin', 'lineCoupon']} excludeProviders={['line']} showDrawer={showDrawer}/>
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};