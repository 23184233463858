
import React, {useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import ExampleWidget from './widgets/ExampleWidget'

export default (props) => {
  const types = props.types || ['lineApp','snsBranding','share','keepWatching','password'].filter(x => !props.exclude || props.exclude.indexOf(x) < 0)
  const title = props.title || 'もっとリタッチを活用する'
  return (
	<section className="services-area ptb-100 bg-e3fbff">
	  <div className="container">
		<div className="section-title">
		  <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Campaign Examples
                    </span>
		  <h2>{title}</h2>
		</div>

		<div className="row">
		  {types.map(type => {
		    return (
			  <div className={"col-lg-4 col-sm-6 col-xs-6"}>
				<ExampleWidget type={type} size="large" onClick={(id, title) =>props.showDrawer(id, title)} hideDescription={true}/>
			  </div>
			)
		  })}
		</div>
	  </div>

	</section>
  )
}