import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import LineCampaign from '../../../components/Examples/LineCampaign'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/line/line.jpg';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="LINEのキャンペーン事例・サンプル"
		  desc="リタッチではLINEアプリでキャンペーンを開催できます。友だち追加やシェア、ショップカードを取得の応募条件を設定したり、リツイートやリポストなど他のSNSと連携し拡散させることも可能です。"
		  banner={exampleMetaImage}
		  pathname={'/examples/line'}
		  type={'Article'}
		  shortName="LINEのキャンペーン事例・サンプル"
		  createdAt="2021-07-26"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="LINEキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />

	  <LineCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples